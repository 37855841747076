$(document).on("turbo:load", function () {
  $(".admin-group-form #group_function").on("change", function () {
    var class_names = [
      "users-assigned-to-single-movie",
      "users-assigned-to-single-section",
      "users-assigned-to-multiple-movies",
      "users-assigned-to-multiple-sections",
    ];

    $.each(class_names, function (_, class_name) {
      $("." + class_name).addClass("d-none");
    });

    $("." + $(this).val().replace(/_/g, "-")).removeClass("d-none");
  });
});
