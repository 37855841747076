// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import { initConfirm } from 'modules/_confirm';
Rails.start();
window.Rails = Rails; // for Rails.fire
initConfirm(Rails);

import "@hotwired/turbo-rails";

require("@rails/activestorage").start();
require("channels");

import { Application } from "@hotwired/stimulus"
const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

$(document).on("turbo:load", function () {
  jscolor.install();
});

// import jscolor from "@eastdesire/jscolor";
import jscolor from "vendor/jscolor";
jscolor.presets.default = {
  format: "rgba",
  required: false,
  closeButton: true,
  clearButton: true,
};

import * as bootstrap from "bootstrap";
import "select2";

require("components/charts");
require("components/floating-presentation-widget");
require("components/jquery.raty");
require("@nathanvda/cocoon");
require("summernote/dist/summernote-lite");

require("modules/_datetime-picker");
require("modules/_listing_page");
require("modules/_players");
require("modules/_presentation_editor");
require("modules/_search");
require("modules/_timeline");
require("modules/comments");
require("modules/infinite-scroll");
require("modules/owner_dashboard");
require("modules/project");
require("modules/summernote");
require("modules/_filter_toggle");
require("partials/_admin_movies");
require("partials/_auto_submit");
require("partials/_check_file");
require("partials/_form_widget");
require("partials/_lists");
require("partials/_groups");
require("partials/_modal_on_load");
require("partials/_live_chat");
require("partials/_matomo");
require("partials/_movies");
require("partials/_mollie");
require("partials/_presentation");
require("partials/_project_voicemail");
require("partials/_range_input");
require("partials/_rate");
require("partials/_remote_target");
require("partials/_s3_upload");
require("partials/_select2");
require("partials/_session");
require("partials/_table_with_checkbox");
require("partials/_voicemail");
require("partials/_countdown");
require("partials/_form");
require("partials/_jitsi");
require("vendor/jquery.inifinite-scroll-up-n-down");

document.addEventListener("scroll", () => {
  document.documentElement.dataset.scroll = window.scrollY;
});

$(document).on("turbo:load", function () {
  var toastElList = [].slice.call(
    document.querySelectorAll(".flash-toast .toast")
  );
  toastElList.map(function (toastEl) {
    new bootstrap.Toast(toastEl).show();
  });

  if (
    window.location.hash &&
    window.location.hash == "#movie_file-upload-form-modal"
  ) {
    let element = document.querySelector(window.location.hash);
    if (element.classList.contains("modal")) {
      new bootstrap.Modal(element).show();
    }
  }
});
